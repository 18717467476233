.checkout_main_block {
    padding-top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.checkout_main_block .checkout_main_block_inner {
    flex: 1 0 auto;
}

.checkout_main_outer {
    position: relative;
    height: 100%;
}

.checkout_right_block {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(50% - 150px);
    height: 100%;
    background: var(--lightGrey);
    z-index: -10;
}

.checkout_top_headline {
    text-align: center;
    background-color: #000;
    color: var(--white);
    padding: 5px 10px;
}

.checkout_top_headline p {
    color: var(--white);
}

.checkout_row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}

.checkout_col.checkout_col_left {
    width: 60%;
    max-width: 60%;
    flex: 0 0 60%;
    padding: 40px 20px 0px 15px;
}

.checkout_checkbox_outer {
    margin-bottom: 30px;
}

.checkout_col.checkout_col_right {
    width: 40%;
    max-width: 40%;
    flex: 0 0 40%;
    padding: 40px 15px 40px 20px;
}

.col_3 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
}

.col_4 {
    width: 33.333333%;
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
}

.col_5 {
    width: 41.666667%;
    max-width: 41.666667%;
    flex: 0 0 41.666667%;
}

.col_6 {
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
}

.col_7 {
    width: 58.333333%;
    max-width: 58.333333%;
    flex: 0 0 58.333333%;
}

.col_8 {
    width: 66.666667%;
    max-width: 66.666667%;
    flex: 0 0 66.666667%;
}

.col_9 {
    width: 75%;
    max-width: 75%;
    flex: 0 0 75%;
}

.checkout_col {
    padding: 0 5px;
    min-height: 1px;
    width: 100%;
}

.col {
    padding: 0 5px;
    min-height: 1px;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.checkout_logo_block {
    padding: 0 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.checkout_logo_block img {
    /* max-height: 50px;
    max-width: 80%; */
    vertical-align: middle;
}

.checkout_logo_image>img {
    /* max-height: 40px;
    max-width: 130px; */
    vertical-align: middle;
    /* width: auto; */
}

.img-fluid {
    max-width: 100%;
    vertical-align: middle;
}

.checkout_radio_outer {
    border: var(--border-color) 1px solid;
    background-color: var(--white);
}

.checkout_radio_outer+.checkout_radio_outer {
    margin-top: -1px;
    position: relative;
    z-index: 1;
}
.InputContainer .InputElement {
    color: var(--white);
}

.checkout_radio_outer:first-of-type {
    border-radius: 5px 5px 0 0;
}

.checkout_radio_outer:last-of-type {
    border-radius: 0 0 5px 5px;
}

.checkout_footer {
    text-align: center;
}

.checkout_footer * {
    font-family: inherit !important;
}

.checkout_footer_outer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin-top: 20px;
    border-top: #eee 1px solid;
    text-align: center;
}

.checkout_footer_links {
    margin-bottom: 5px;
}

.checkout_footer_links a {
    padding: 5px 10px;
}

.checkout_footer_links a:hover {
    text-decoration: underline !important;
}

.checkout_footer_outer * {
    font-size: 12px;
    color: #666;
}

.checkout_checkbox_text {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
}

.checkout_feature_image {
    padding: 40px 0;
}

.checkout_feature_testimonial {
    padding-left: 10px;
    border-left: var(--border-color) 2px solid;
}

.client_msg {
    position: relative;
}

.client_msg:before {
    content: "";
    position: absolute;
    top: -15px;
    left: 15px;
    width: 50px;
    height: 50px;
    background-image: url("../../assets/images/quote.svg");
    background-position: 0 0;
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0.1;
}

.client_msg p {
    font-size: 12px;
    line-height: 1.5;
}

.client_img {
    margin-bottom: 10px;
}

.client_img img {
    max-height: 80px;
    border-radius: 15px;
}

.cart_summary_mobile {
    display: block;
    white-space: nowrap;
    background: var(--white);
    padding: 15px;
    border: var(--border-color) 1px solid;
    border-radius: 5px;
    /* margin-left: 20px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 5px;
}

.cart_summary_price {
    margin: 0 10px;
    vertical-align: middle;
}

.cart_summary_mobile .arrow {
    -webkit-transition: ease 0.2s;
    -moz-transition: ease 0.2s;
    transition: ease 0.2s;
}

.cart_summary_mobile.active .arrow {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}

.cart_summary_mobile.active {
    background-color: var(--lightGrey);
}

.checkout_footer_cards {
    text-align: center;
    padding-bottom: 20px;
}

.checkout_footer_cards img {
    max-width: 80%;
}

.react-tel-input input.checkout_input {
    /* padding-left: 50px; */
}

.react-tel-input .flag-dropdown {
    background-color: var(--white);
    border-color: var(--border-color);
}

.react-tel-input .country-list .search .search-emoji {
    display: none;
}

.react-tel-input .country-list .search-box {
    width: 100%;
    margin: 0;
}

.sticky {
    position: sticky;
    top: 40px;
}

.shipping_address_outer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.address_dropdown {
    position: relative;
}

.address_dropdown>button.address_dropdown_placeholder {
    font-weight: 500;
    padding: 8px 15px;
    border: var(--border-color) 1px solid;
    border-radius: 5px;
    margin-bottom: 10px;
}

.address_dropdown_outer {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--white);
    border: var(--border-color) 1px solid;
    border-radius: 10px;
    z-index: 9999;
    min-width: 200px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px;
    overflow: hidden;
    max-height: 300px;
    overflow-y: auto;
}

.address_dropdown_outer button {
    text-align: left;
    padding: 10px 15px;
    width: 100%;
}

.address_dropdown_outer button:hover,
.address_dropdown_outer button.active {
    background-color: var(--white);
}

.address_dropdown_outer button+button {
    border-top: var(--border-color) 1px solid;
}

.checkout_cart_summary {
    padding-bottom: 20px;
}

body.page_loading:after {
    position: fixed;
    top: 0;
    content: "Please wait, while we are fetching order details...";
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--white);
    z-index: 9999;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    font-size: 18px;
    font-weight: 500;
}

.paymentOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    z-index: 9999;
    text-align: center;
    padding: 20px;
}

.paymentOverlay * {
    font-weight: normal;
}

.md_show .checkout_cart_summary {
    border-bottom: var(--offwhite) 1px solid;
    margin-bottom: 20px;
}
.pagenotFound{
    text-align: center;
    padding: 0 15px;
}
.checkout_main_block_inner.pagenotFound_outer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagenotFound img {
    max-width: 100%;
}
.pagenotFound h1 {
    font-size: 40px;
    margin: 20px 0 0px;
}
.pagenotFound p {
    font-size: 16px;
}
.dropFrame iframe {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
}

.dropFrame {
    position: relative;
    width: 100%;
    height: 90vh;
}

@media screen and (max-width: 992px) {

    .checkout_col.checkout_col_left,
    .checkout_col.checkout_col_right {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        padding: 40px 15px;
    }

    .checkout_right_block {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .sm_show .checkout_cart_summary {
        background-color: var(--lightGrey);
        padding: 15px;
        border: var(--border-color) 1px solid;
        border-width: 1px 0;
        margin-bottom: 30px;
    }

    .checkout_logo_block {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;
    }

    .checkout_feature_image {
        padding: 0 0 40px 0;
    }
}

@media screen and (max-width: 575px) {
    [class*=col_] {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
    }

    .col_sm_6 {
        width: 50%;
        max-width: 50%;
        flex: 0 0 50%;
    }

    .checkout_top_headline p {
        font-size: 12px;
    }

    .checkout_col.checkout_col_left,
    .checkout_col.checkout_col_right {
        padding: 20px 10px;
    }

    .checkout_col.checkout_col_right {
        background: none !important;
    }

    .checkout_logo_image {
        flex-basis: 50%;
    }

    .cart_summary_mobile {
        padding: 12px;
    }

    .paymentOverlay h3{
        font-size: 16px;
    }
    .pagenotFound h1 {
        font-size: 30px;
        margin: 0px;
    }
}

@media (prefers-color-scheme: dark) {
    .checkout_checkbox_text img[alt="Cashfree"]{
        filter: invert(1);
    }
}