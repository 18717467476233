.form_outer {
    margin-bottom: 15px;
}

.form_outer label:not(.checkout_checkbox) {
    font-weight: 700;
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
}

.form_outer label:not(.checkout_checkbox) p {
    font-weight: 400;
    display: block;
    font-size: 14px;
}

.checkout_input,
.form_control select {
    /* background-color: var(--offwhite); */
    width: 100%;
    padding: 12px 15px;
    color: var(--text-color);
    border-radius: 4px;
    appearance: none;
    font-size: 14px;
    border: #d9d9d9 1px solid;
    background-color: var(--input);
}

.stripe_input {
    background-color: #fff;
}

.payment_gateway_form .checkout_input {
    padding: 12px 15px;
}

div.checkout_input {
    padding: 14px 15px;
    line-height: normal;
}

.checkout_input.error,
.form_control select.error {
    /* border: var(--error-color) 1px solid !important; */
    color: var(--error-color) !important;
    background-color: rgba(255, 0, 0, 0.05);
}

.form_control select {
    appearance: none;
    cursor: pointer;
    padding-right: 30px;
}

.form_control.custom_select:after {
    content: "";
    background-image: url("../../../assets/images/arrow-down.svg");
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: calc(100% - 15px) 50%;
    position: absolute;
    /* top: 50%; */
    bottom: 10px;
    right: 0;
    width: 30px;
    height: 30px;
    z-index: 99;
    /* transform: translateY(-50%); */
}

.form_control label.form_label {
    /* position: absolute;
    top: -9px;
    left: 15px; */
    font-size: 14px;
    font-weight: 400;
    color: var(--darkGrey);
    letter-spacing: 0;
    margin-bottom: 0;
    z-index: 9;
    pointer-events: none;
    padding: 0 4px;
}

.form_control {
    position: relative;
    margin-bottom: 15px;
}

.special_offer_block p {
    font-size: 12px;
    line-height: 1.2;
}

.special_offer_block p a {
    text-decoration: underline !important;
}

.input_error {
    color: #ff0000 !important;
}


@media (prefers-color-scheme: dark) {
    .form_control.custom_select:after {
        filter: invert(1);
    }

    .stripe_input {
        filter: invert(1);
    }
}