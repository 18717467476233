.checkout_cards img {
    /* margin-right: 5px; */
    vertical-align: middle;
    height: 15px;
}

.checkout_cards {
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.payment_gateway_form {
    padding: 0 40px 15px;
}

span.cvv_tooltip {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    border: #d9d9d9 1px solid;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
    border-radius: 50%;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    color: #6c6b6b;
}

.cvv_tooltip_outer {
    position: absolute;
    top: 20px;
    left: 100%;
    padding: 10px;
    background: #fff;
    z-index: 9999;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 7px;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
    transition: ease 0.3s;
    pointer-events: none;
}

.cvv_tooltip_outer img {
    max-width: 100%;
    vertical-align: middle;
}

span.cvv_tooltip:hover+.cvv_tooltip_outer {
    opacity: 1;
    visibility: visible;
}

.cvv_tooltip_outer p {
    font-size: 10px;
    padding-bottom: 10px;
    display: block;
}

@media screen and (max-width:1199px) {
    .cvv_tooltip_outer {
        top: 40px;
        right: 0;
        left: auto;
    }
}

@media screen and (max-width:575px) {
    .checkout_cards img {
        margin-right: 3px;
        max-width: 20px;
    }

    .payment_gateway_form {
        padding: 0 15px 15px 40px;
    }

    .payment_gateway_form .checkout_input {
        padding: 10px 12px;
    }
    .checkout_cards {
        font-size: 12px;
    }
    .checkout_btn_outer p {
        font-size: 12px;
    }
}