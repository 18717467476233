.checkout_cart_item{
    display: flex;
    align-items: flex-start;
}
.checkout_cart_item + .checkout_cart_item{
    padding-top: 15px;
    margin-top: 15px;
    border-top: var(--border-color) 1px dashed;
}
.cart_img{
    flex: 0 0 60px;
    width: 100%;
    max-width: 60px;
}
.cart_img img{
    border: var(--border-color) 1px solid;
    padding: 2px;
    width: 100%;
}
.checkout_cart_info{
    width: 100%;
    padding-left: 15px;
    display: flex;
    justify-content: space-between;
}
.checkout_cart_info h6{
    margin-bottom: 3px;
    word-break: break-word;
}
.checkout_cart_info .checkout_cart_price{
    letter-spacing: 0;
    margin-bottom: 0;
    white-space: nowrap;
    padding-left: 15px;
}
.checkout_cart_info .checkout_cart_price p{
    font-size: 12px;
}
.checkout_cart_total ul li{
    display: flex;
    justify-content: space-between;
    padding: 2px 0;
    font-size: 14px;
}
.checkout_cart_total ul li:last-of-type {
    padding-bottom: 0;
}
.checkout_cart_total ul li > span {
    white-space: nowrap;
}
.checkout_cart_total ul li label{
    font-weight: 400;    
}
.checkout_cart_total hr{
    margin: 0.5rem 0;
}
.checkout_cart_list {
    max-height: 270px;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 20px;
}
.remove_discount{
    color: #ff0000;
    margin-left: 10px;
    font-size: 12px;
}
.checkout_discount .form_control{
    margin-bottom: 5px;
}
.added_discount {
    padding: 5px 10px;
    background: var(--EEE);
    border-radius: 5px;
    display: inline-block;
    margin-top: 6px;
    font-size: 14px;
}
.added_discount img{
    vertical-align: middle;
}